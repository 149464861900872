<template>
  <v-container fluid class="page-container">
    <template v-if="initialized">
      <v-fab-transition>
        <v-btn v-show="!showInputForm" color="primary" fab class="fab" @click="showInputForm = !showInputForm"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-fab-transition>
      <v-slide-y-reverse-transition>
        <labs-wandh-input-form
          v-show="showInputForm"
          :state="state"
          :abort-controller="abortController"
          :show-close-button="true"
          @close="showInputForm = false"
        />
      </v-slide-y-reverse-transition>
      <v-slide-y-transition>
        <v-row v-if="sentQuery" ref="resultWrapper" class="result-wrapper">
          <holmes
            ref="holmes"
            :sent-query="sentQuery"
            :state="state"
            :answer="answer"
            :answer-timered="answerTimered"
            :error="error"
            :selected-reference="selectedReference"
            :handle-select-reference="handleSelectReference"
            :references="references"
            :laws="laws"
          />
          <watson
            ref="watson"
            v-bind="{
              sentQuery,
              state,
              selectedReference,
              handleSelectReference,
              handleUnfocusReference,
              references,
              answer,
              watsonTab,
              watsonTabs,
              changeWatsonTab,
              laws,
            }"
          />
        </v-row>
      </v-slide-y-transition>
    </template>
    <v-progress-circular v-else indeterminate size="64" class="loading" />
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, ProvideReactive, Vue, Watch } from 'nuxt-property-decorator';
import LabsWandhInputForm from '@/components/labs/wandh/input-form.vue';
import Watson from '@/components/labs/wandh/watson.vue';
import Holmes from '@/components/labs/wandh/holmes.vue';
import { DocRecord, DocumentTypeEnum } from 'wklr-backend-sdk/models';
import type {
  CaseReferenceMetadata,
  Evaluation,
  LabsWandhQuestionHistory,
  LabsWandhStreamParams,
  QuestionParams,
  PostLabsWandhQuestionReference,
} from 'wklr-backend-sdk/repos/labs';
import type { RecursivePartialBy } from '@/types/PartialBy';
import { isAxiosError } from '@/utils/axiosUtis';
import * as labsUtils from '@/utils/labsUtils';
import { Context } from '@nuxt/types';
import { WandhState } from '@/components/labs/wandh/constants';
import { paths } from 'wklr-backend-sdk/wandh';
import { Court2Rank } from './courts';

@Component({
  layout: 'labs',
  components: {
    LabsWandhInputForm,
    Watson,
    Holmes,
  },
})
export default class WandhUI extends Vue {
  @Prop() questionHistory?: LabsWandhQuestionHistory;

  $refs!: Record<string, HTMLElement[] | undefined> &
    Record<'referenceItems', (Vue & { $el: HTMLElement }[]) | undefined> &
    Record<'resultWrapper' | 'questionTextarea', HTMLElement | undefined> &
    Record<'watson', InstanceType<typeof Watson> | undefined> &
    Record<'holmes', InstanceType<typeof Holmes> | undefined>;

  initialized = false;

  state: WandhState = 'idle';

  sentQuery: RecursivePartialBy<QuestionParams, 'filter'> | null = null;
  abortController: AbortController | null = null;
  /** Holmesが指すrefからWatsonの結果の何番目の何個目を指しているのかの対応表 */
  referenceIndexMap: { document: number; section: number | null }[] = [];

  @ProvideReactive('currentSessionID')
  currentSessionID: string | null = null;
  answer:
    | {
        text: string;
        ref?: {
          id: number;
          displayID: string;
          detailedDisplayID: string;
          document: number;
          section: number | null;
        }[];
      }[]
    | 'N/A'
    | 'skipped'
    | null = null;
  error: boolean | null = null;

  /** FIXME: 文献リストが書籍しかなかった時の前提のままlinearな配列になっているが、違う文書タイプの登場＆typeがdiscriminatorとして不適らしいので設計を考え直すべき時に来ている */
  @ProvideReactive('references')
  references: (
    | {
        record: DocRecord & { type: Exclude<DocumentTypeEnum, 'case'> };
        references: { key: number; content: string; sectionLabels: string[]; refIndex: number }[];
      }
    | {
        record: CaseReferenceMetadata;
        references: [
          {
            youshi: {
              shown: (CaseReferenceMetadata['youshi'][0] & { youshiIndex: number })[];
              hidden: (CaseReferenceMetadata['youshi'][0] & { youshiIndex: number })[];
            };
            jikou: CaseReferenceMetadata['jikou'];
            refIndex: number;
          },
        ] /** FIXME 型合わせが面倒すぎて意味無く配列にしてしまった */;
      }
    | {
        record: {
          id: string;
          url: string;
          type: 'guideline' | 'guideline_qa' | 'tsutatsu' | 'tsutatsu_qa';
          title: string;
          publisher: string;
          publishedOn: number;
          collectedAt?: Date;
          naturalId: string;
        };
        references: [
          {
            id: string;
            pageNumbers: number[];
            pdfPageNumbers: number[];
            content: string;
            headings: string[];
            refIndex: number;
          },
        ];
      }
  )[] = [];

  @ProvideReactive('laws')
  laws: {
    label: string;
    docId: string;
    series: number;
    key: number;
    text: string;
    title: string;
  }[] = [];

  @ProvideReactive('evaluation')
  evaluation: Required<Evaluation> = this.initialEvaluation();

  timerStarted = false;
  answerTimered: {
    text: string;
    ref?: {
      id: number;
      displayID: string;
      detailedDisplayID: string;
      document: number;
      section: number | null;
    }[];
  }[] = [];
  answerTimerCount = 0;
  answerInterval = {
    'holmes-thinking': 30, // ms
    buffering: 20, // ms
  };
  lastHiddenTime = 0;

  selectedReference: {
    type: DocumentTypeEnum | 'guideline_qa' | 'tsutatsu' | 'tsutatsu_qa';
    document: number;
    section: number | null;
    focus: boolean;
    scroll: boolean;
  } | null = null;
  showEvaluationWatsonDetails = false;

  showInputForm = true;

  async asyncData({ app: { $repositories, $store }, error }: Context) {
    try {
      const res = await $repositories.labs.getLabsWandhQuota();
      $store.commit('updateLabsWandhQuota', res);
    } catch (e) {
      if (isAxiosError(e)) {
        error({ statusCode: e.response?.status, message: e.response?.data?.message });
      } else {
        throw e;
      }
    }
  }

  setParams() {
    const params: LabsWandhStreamParams = this.$store.state.persistent.labs.wandh.streamParams;
    if (params && params.historyId === this.$router.currentRoute.params.id) {
      // TODO ここでリセットしてよいか？
      this.$store.commit('resetLabsWandhStreamParams');
      this.ask(params);
    } else {
      this.renderHistory();
    }
  }

  mounted() {
    setTimeout(() => this.setParams(), 1500);
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  initialEvaluation(): Required<Evaluation> {
    // プロパティ watson はもう使っていないが、互換性のために残っている
    return {
      holmes: { generations: [] },
      watson: {},
      watsonB: {},
      watsonC: {},
    };
  }

  isHistory = false;

  async renderHistory() {
    const history = await this.$repositories.labs.getLabsWandhQuestionHistory(this.$route.params.id);
    this.initialize({ isHistory: true, answerTimerCount: 100000, evaluation: history.evaluation });
    this.sentQuery = history.parameters;

    this.$nextTick(() => this.$refs.resultWrapper?.scrollIntoView({ behavior: 'smooth' }));

    let answer = '';
    this.timerStarted = false;

    for (const response of history.apiResponses) {
      answer = await this.handleResponse(answer, response);
    }

    if (answer.match(/^.{0,25}N\/A.{0,25}$/) || answer === '[]') {
      this.answer = 'N/A';
    }

    // onAnswerTimer を終わらせるために
    this.state = this.timerStarted ? 'buffering' : 'idle';
  }

  async ask(streamParams: LabsWandhStreamParams) {
    // W&Hの停止
    if (this.abortController) {
      this.abortController.abort('UserAbort');
      this.abortController = null;
    }
    // 初期化
    this.initialize();
    this.sentQuery = streamParams.query;

    this.$nextTick(() => this.$refs.resultWrapper?.scrollIntoView({ behavior: 'smooth' }));

    let answer = '';
    this.timerStarted = false;

    // streamとクオータの取得
    const { controller, stream } = await this.$repositories.labs.getLabsWandhStream(streamParams);
    this.abortController = controller;
    this.updateQuota(); // 敢えてawaitしない

    // streamの処理
    try {
      for await (const response of await stream()) {
        answer = await this.handleResponse(answer, response);
      }
    } catch (error) {
      if (controller.signal.aborted && controller.signal.reason === 'UserAbort') {
        return;
      } else {
        console.error({ error });
        this.$toast.error(
          'サーバーとの通信に失敗しました。時間をおいて再度お試しください。<br>' +
            '繰り返しこのエラーが表示される場合は、ネットワーク管理者にお問い合わせください。',
        );
        this.$telemetry.sendErrorTelemetry(error as Error, this.$route);
        this.error = true;
      }
    }

    if (this.error === null) {
      this.error = true;
    }

    if (answer.match(/^.{0,25}N\/A.{0,25}$/) || answer === '[]') {
      this.answer = 'N/A';
    }

    this.state = this.timerStarted ? 'buffering' : 'idle';
  }

  // 新規質問完了時、新規にした質問を先頭に追加する
  @Watch('state')
  async onStateChange() {
    if (this.state === 'idle' && !this.isHistory) {
      await this.$store.dispatch('loadLatestWandhHistory');
    }
  }

  initialize(options: { isHistory?: boolean; answerTimerCount?: number; evaluation?: Evaluation | null } = {}) {
    this.initialized = true;
    this.isHistory = options.isHistory ?? false;
    this.answer = null;
    this.references = [];
    this.state = 'watson-thinking';
    this.answerTimerCount = options.answerTimerCount ?? 0;
    this.currentSessionID = null;
    // 履歴から取得された評価は必要なプロパティを持たないことがあるので、互換性を保ちつつ型のためにマージする
    this.evaluation = { ...this.initialEvaluation(), ...options.evaluation };
    this.error = null;
  }

  referenceIndexSortedToOriginal: Map<number, number> = new Map();
  countOfBookReferences = 0;
  countOfCaseReferences = 0;
  countOfGuidelineReferences = 0;

  async handleResponse(
    answer: string,
    response: paths['/question/']['post']['responses'][200]['content']['application/json'],
  ): Promise<string> {
    if (response.role === 'watson') {
      let references = response.references;
      // Holmesのreference番号とマッチするためソート前のindexをセーブ
      let referencesWithIndices = references.map((val, index) => {
        return { val, index };
      });

      // split referencesWithIndices by type
      let referencesWithIndicesByType = referencesWithIndices.reduce(
        (acc, e) => {
          if (e.val.metadata.type === 'book') {
            acc.book.push(e);
          } else if (e.val.metadata.type === 'case') {
            acc.case.push(e);
          } else if (e.val.metadata.type === 'guideline' || e.val.metadata.type === 'guideline_qa') {
            acc.guideline.push(e);
          } else if (e.val.metadata.type === 'tsutatsu' || e.val.metadata.type === 'tsutatsu_qa') {
            acc.tsutatsu.push(e);
          }
          return acc;
        },
        { book: [], case: [], guideline: [], tsutatsu: [] } as Record<
          'book' | 'case' | 'guideline' | 'tsutatsu',
          { val: PostLabsWandhQuestionReference; index: number }[]
        >,
      );
      // sort type 'case' only
      referencesWithIndicesByType.case.sort((a, b) => {
        let aKey = 9999;
        let bKey = 9999;
        if (a.val.metadata.type === 'case' && a.val.metadata.court in Court2Rank) {
          aKey = Court2Rank[a.val.metadata.court as keyof typeof Court2Rank];
        }
        if (b.val.metadata.type === 'case' && b.val.metadata.court in Court2Rank) {
          bKey = Court2Rank[b.val.metadata.court as keyof typeof Court2Rank];
        }
        return aKey - bKey;
      });

      // recover referencesWithIndices
      referencesWithIndices = [
        ...referencesWithIndicesByType.book,
        ...referencesWithIndicesByType.case,
        ...referencesWithIndicesByType.guideline,
        ...referencesWithIndicesByType.tsutatsu,
      ];

      // let referenceIndexSortedToOriginal: Record<number, number> = {};

      // ソート前のindex=>ソート後のindexのマップを作る
      referencesWithIndices.forEach((item, newIndex) => {
        this.referenceIndexSortedToOriginal.set(newIndex, item.index);
      });

      references = referencesWithIndices.map((e) => e.val);

      this.referenceIndexMap = await this.processReferences(references);

      this.countOfBookReferences = references.filter((r) => r.metadata.type === 'book').length;
      this.countOfCaseReferences = references.filter((r) => r.metadata.type === 'case').length;
      this.countOfGuidelineReferences = references.filter(
        (r) =>
          r.metadata.type === 'guideline' ||
          r.metadata.type === 'guideline_qa' ||
          r.metadata.type === 'tsutatsu' ||
          r.metadata.type === 'tsutatsu_qa',
      ).length;
      this.currentSessionID = response.session;
      this.state = 'holmes-thinking';
    } else if (response.role === 'holmes') {
      answer += response.chunk;
      const parsedAnswer = labsUtils.parseStreamingJSON(answer);

      this.answer = parsedAnswer.map(this.formatReference);

      if (!this.timerStarted) {
        this.timerStarted = true;
        this.onAnswerTimer();
      }
    } else if (response.role === 'watsonLaw') {
      let laws = response.laws;

      this.laws = laws.map((law) => {
        return {
          label: law.label,
          docId: law.docId,
          series: law.series,
          key: law.key,
          text: law.text,
          title: law.title,
        };
      });
    } else {
      this.error = !response.success;

      if (response.holmesSkipped) {
        this.answer = 'skipped';
      }
    }

    return answer;
  }

  /** Holmesが指すrefからWatsonの結果の何番目の何個目を指しているのかの対応表を作る */
  async processReferences(references: PostLabsWandhQuestionReference[]) {
    // 適当の値で初期化
    const referenceIndexMap = Array.from({ length: references.length }, () => ({
      document: 0,
      section: 0 as number | null,
    }));

    let i = 0;
    for (const { metadata } of references) {
      const iOriginal = this.referenceIndexSortedToOriginal.get(i);
      if (iOriginal === undefined) {
        continue;
      }

      if (metadata.type === 'book') {
        const { id, type, title, author, content, publisher, pos_label, published_on, section } = metadata;
        const sectionRef = { key: section, content, sectionLabels: pos_label, refIndex: iOriginal + 1 };

        // IDが衝突する可能性もある
        const found = this.references.findIndex((r) => r.record.id === id);

        if (found !== -1) {
          referenceIndexMap[iOriginal] = { document: found, section: this.references[found].references.length };
          (this.references[found].references as (typeof sectionRef)[]).push(sectionRef);
        } else {
          referenceIndexMap[iOriginal] = { document: this.references.length, section: 0 };
          this.references.push({
            record: {
              docAccessible: true,
              id,
              uri: '',
              type,
              title,
              authors: author,
              publisher,
              publishedOn: new Date(published_on * 1000).toISOString(),
              thumbnailURI: `https://static.legalscape.jp/thumbnail/${id}.png`,
              pdfFileURI: 'dummy',
              isWebViewAvailable: true,
              toc: null,
              packs: [],
            },
            references: [sectionRef],
          });
        }
      } else if (metadata.type === 'case') {
        referenceIndexMap[iOriginal] = { document: this.references.length, section: null };
        const youshi = metadata.youshi.reduce(
          (acc, youshi, youshiIndex) => {
            (youshi.score ? acc.shown : acc.hidden).push({ youshiIndex, ...youshi });
            return acc;
          },
          { shown: [], hidden: [] } as Record<
            'shown' | 'hidden',
            ((typeof metadata.youshi)[0] & { youshiIndex: number })[]
          >,
        );

        if (youshi.shown.length < 3) {
          youshi.shown.push(...youshi.hidden.splice(0, 3 - youshi.shown.length));
        }

        this.references.push({
          record: metadata,
          references: [{ youshi, jikou: metadata.jikou, refIndex: iOriginal + 1 }],
        });
      } else if (
        metadata.type === 'guideline' ||
        metadata.type === 'guideline_qa' ||
        metadata.type === 'tsutatsu' ||
        metadata.type === 'tsutatsu_qa'
      ) {
        const guidelineId = metadata.id.split('.')[0];
        const found = this.references.findIndex((r) => r.record.id === guidelineId);

        const type = metadata.type === 'guideline' || metadata.type === 'guideline_qa' ? 'guideline' : 'tsutatsu';

        if (found !== -1) {
          referenceIndexMap[iOriginal] = { document: found, section: this.references[found].references.length };
          (
            this.references[found].references as {
              id: string;
              pageNumbers: number[];
              pdfPageNumbers: number[];
              content: string;
              refIndex: number;
              headings: string[];
            }[]
          ).push({
            id: metadata.id,
            pageNumbers: metadata.page_numbers,
            pdfPageNumbers: metadata.pdf_page_numbers,
            content: metadata.content,
            refIndex: iOriginal + 1,
            headings: metadata.headings,
          });
        } else {
          let collected_at: Date | undefined;
          if (metadata.published_on <= 0) {
            const record = await this.$repositories.docs.getExtended(metadata.natural_id);
            if (record && record.collectedAt) {
              collected_at = new Date(record.collectedAt);
            }
          }
          referenceIndexMap[iOriginal] = { document: this.references.length, section: 0 };
          this.references.push({
            record: {
              id: metadata.id.split('.')[0],
              title: metadata.title,
              type: type,
              publishedOn: metadata.published_on,
              collectedAt: collected_at,
              publisher: metadata.publisher,
              url: metadata.url,
              naturalId: metadata.natural_id,
            },
            references: [
              {
                id: metadata.id,
                pageNumbers: metadata.page_numbers,
                pdfPageNumbers: metadata.pdf_page_numbers,
                content: metadata.content,
                refIndex: iOriginal + 1,
                headings: metadata.headings,
              },
            ],
          });
        }
      } else {
        console.error('Unexpected metadata type', metadata);
      }
      i++;
    }

    return referenceIndexMap;
  }

  formatReference({ text, ref }: { text: string; ref?: number | number[] | null }) {
    if (
      ref == null || // null の場合は何もしない
      (typeof ref !== 'number' && !Array.isArray(ref)) || // number でも number[] でもない場合は何もしない
      (typeof ref === 'number' && (ref <= 0 || ref > this.referenceIndexMap.length)) || // number で範囲外の場合は何もしない
      (Array.isArray(ref) && ref.some((r) => r <= 0 || r > this.referenceIndexMap.length)) // number[] で範囲外の場合は何もしない
    ) {
      return { text };
    }

    const isMultiRefs = Array.isArray(ref);
    const refs = isMultiRefs ? ref : [ref];

    const MAX_DISPLAYED_REFS = 5;
    if (refs.length > MAX_DISPLAYED_REFS) {
      refs.splice(MAX_DISPLAYED_REFS);
    }

    const refsFormatted = refs.map((ref) => {
      const { document, section } = this.referenceIndexMap[ref - 1];

      const record = this.references[document].record;

      if (record.type === 'book') {
        if (section == null) {
          throw new Error('Unexpected book reference metadata');
        }

        const sectionDisplayID = this.references[document].references.length > 1 ? ` §${section + 1}` : '';

        // refはHolmesが生成前に参照した番号なので、ソートされた後の番号に変換する
        let sortedRef: number | null = null;
        for (const [newIndex, originalIndex] of this.referenceIndexSortedToOriginal.entries()) {
          if (originalIndex === ref - 1) {
            sortedRef = newIndex + 1;
            break;
          }
        }
        if (sortedRef == null) {
          throw new Error('Unexpected case reference metadata');
        }

        return {
          id: ref,
          displayID: isMultiRefs ? sortedRef.toString() : record.title + sectionDisplayID,
          detailedDisplayID: record.title + sectionDisplayID,
          document,
          section,
        };
      } else if (record.type === 'case') {
        const references = this.references[document].references[0];

        if (!('youshi' in references)) {
          throw new Error('Unexpected case reference metadata');
        }

        const youshi =
          '要旨 ' +
          references.youshi.shown
            .filter(({ score }) => score)
            .map(({ youshiIndex }) => String(youshiIndex + 1))
            .join(', ');

        const jikou = references.jikou.length === 0 ? '' : `・判示事項等 1`;

        const title = !record.caseName ? record.displayName : `${record.displayName}（${record.caseName}）`;

        // refはHolmesが生成前に参照した番号なので、ソートされた後の番号に変換する
        let sortedRef: number | null = null;
        for (const [newIndex, originalIndex] of this.referenceIndexSortedToOriginal.entries()) {
          if (originalIndex === ref - 1) {
            // 判例の参照番号は書籍の参照番号分ずれているので補正する
            sortedRef = newIndex + 1 - this.countOfBookReferences;
            break;
          }
        }
        if (sortedRef == null) {
          throw new Error('Unexpected case reference metadata');
        }

        return {
          id: ref,
          /** ※ Holmes v4.5-4.6のプロンプトで使われる情報を表示しているため、プロンプトを変える場合変更が必要 */
          displayID: isMultiRefs ? sortedRef.toString() : title + youshi + jikou,
          detailedDisplayID: title + youshi + jikou,
          document,
          section,
        };
      } else {
        if (section == null) {
          throw new Error('Unexpected book reference metadata');
        }

        const sectionDisplayID = this.references[document].references.length > 1 ? ` §${section + 1}` : '';

        // refはHolmesが生成前に参照した番号なので、ソートされた後の番号に変換する
        let sortedRef: number | null = null;
        for (const [newIndex, originalIndex] of this.referenceIndexSortedToOriginal.entries()) {
          if (originalIndex === ref - 1) {
            // 判例の参照番号は書籍の参照番号分ずれているので補正する
            let offset = 0;
            if (record.type === 'law') {
              offset = this.countOfBookReferences + this.countOfCaseReferences + this.countOfGuidelineReferences;
            } else {
              offset = this.countOfBookReferences + this.countOfCaseReferences;
            }
            sortedRef = newIndex + 1 - offset;
            break;
          }
        }
        if (sortedRef == null) {
          throw new Error('Unexpected case reference metadata');
        }

        return {
          id: ref,
          displayID: isMultiRefs ? sortedRef.toString() : record.title + sectionDisplayID,
          detailedDisplayID: record.title + sectionDisplayID,
          document,
          section,
        };
      }
    });

    return {
      text,
      ref: refsFormatted,
    };
  }

  onAnswerTimer() {
    if (this.state !== 'holmes-thinking' && this.state !== 'buffering') {
      return;
    }

    if (!Array.isArray(this.answer)) {
      this.state = 'idle';
      return;
    }

    let length = this.answerTimerCount;
    const answer = [];
    for (const paragraph of this.answer) {
      if (paragraph.text.length <= length) {
        length -= paragraph.text.length;
        answer.push(paragraph);
      } else {
        answer.push({ text: paragraph.text.slice(0, length) });
        length = -1;
        this.answerTimered = answer;
        ++this.answerTimerCount;
        break;
      }
    }

    const scroller = this.$refs.holmes?.$refs.answerColumn as HTMLElement | null;
    if (scroller && !scroller.matches(':hover')) {
      scroller.scrollTo(0, scroller.scrollHeight);
    }

    if (length >= 0 && this.state === 'buffering') {
      this.answerTimered = this.answer;
      this.state = 'idle';
      this.abortController = null;
      return;
    }

    setTimeout(() => this.onAnswerTimer(), this.answerInterval[this.state]);
  }

  // Watson の文献を強調表示する
  // Watson内の文献を click した時と Holmes 内の出典表記を focus した時に呼び出される
  handleSelectReference(ref: {
    type: DocumentTypeEnum;
    document: number;
    section: number | null;
    focus: boolean;
    scroll: true;
  }) {
    if (this._handleLeaveReferenceDelayedTimer !== null) {
      clearTimeout(this._handleLeaveReferenceDelayedTimer);
    }
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const scroller = this.$refs.watson!.$refs.tabsItems! as HTMLElement | undefined;

    let refItem;

    if (ref.type === DocumentTypeEnum.Law) {
      refItem = (this.$refs.watson!.$refs.lawItems as
        | (Vue & { $el: HTMLElement; handleShowReadMore: (sectionIndex: number) => void })[]
        | undefined)![ref.document];
    } else {
      refItem = (this.$refs.watson!.$refs.referenceItems as
        | (Vue & { $el: HTMLElement; handleShowReadMore: (sectionIndex: number) => void })[]
        | undefined)![ref.document];
    }
    const refItemEl = refItem.$el;

    const refSectionItem = (() => {
      const _ref =
        ref.type === DocumentTypeEnum.Law
          ? refItem.$refs[`law-${ref.document}`]
          : refItem.$refs[`reference-${ref.document}-${ref.section}`];
      if (!_ref) {
        this.$assert(false, 'Unexpected ref in handleSelectReference (1)', this.$route);
        return;
      }

      if (ref.section != null) {
        // 書籍ref
        return (
          this.$assert(Array.isArray(_ref), 'Unexpected ref in handleSelectReference (2)', this.$route) &&
          (_ref as HTMLElement[])[0]
        );
      } else {
        // 判例ref
        return (
          this.$assert(_ref instanceof HTMLElement, 'Unexpected ref in handleSelectReference (3)', this.$route) &&
          (_ref as HTMLElement)
        );
      }
    })();

    if (!refSectionItem) {
      return;
    }

    setTimeout(async () => {
      if (!this.selectedReference || !scroller) {
        return;
      }

      let tabName = ref.type as string;
      tabName = ['guideline_qa', 'tsutatsu', 'tsutatsu_qa'].includes(tabName) ? 'guideline' : tabName;
      this.changeWatsonTab(tabName);

      await this.$nextTick();

      if (!ref.scroll) {
        return;
      }
      // Watson.vue の v-tabs:height 48px + div.tabs-items:padding 16px + div.reference:padding 4px
      const headerOffset = 68;
      if (window.innerWidth < 960) {
        const rect = refSectionItem.getBoundingClientRect();
        const targetScrollPosition = rect.top + window.scrollY - headerOffset;
        window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
      } else {
        const refItemTop = refItemEl.offsetTop - headerOffset;
        const refSectionItemTop = refItemTop + refSectionItem.offsetTop;
        const refSectionItemBottom = refSectionItemTop + refSectionItem.offsetHeight;
        const scrollerViewHeight = scroller.offsetHeight;
        const refImportantPartHeight = refSectionItemBottom - refSectionItemTop;

        // Watson.vue の .watson-inner-header:height 60px
        const innerHeaderOffset = 60;
        let targetScrollPosition =
          refImportantPartHeight <= scrollerViewHeight - innerHeaderOffset
            ? refSectionItemTop - (scrollerViewHeight - innerHeaderOffset - refImportantPartHeight) / 2
            : refSectionItemTop;
        // 一番上の文献の場合、スクロール位置がマイナスになることがあるので補正し、上下に揺らせるようにする。
        // 一番下の文献の場合の処理も必要だが、参照されることは少ないので省略する。
        if (targetScrollPosition < 0) targetScrollPosition = 0;
        const currentScrollPosition = scroller.scrollTop;

        if (currentScrollPosition === targetScrollPosition) {
          // 初期位置と終了位置が同じ場合、少し上下に揺らす
          const shakeAmount = 10; // 揺らす量（ピクセル）
          scroller.scrollTo({ top: targetScrollPosition + shakeAmount, behavior: 'smooth' });
          // 少し待って元の位置に戻す
          setTimeout(() => {
            scroller.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
          }, 250);
        } else {
          // 通常のスクロール動作
          scroller.scrollTo({
            top: targetScrollPosition,
            behavior: 'smooth',
          });
        }
      }
    }, 250);

    if (ref.type !== DocumentTypeEnum.Case) {
      if (ref.section == null) {
        refItem.handleShowReadMore(ref.document);
      } else {
        refItem.handleShowReadMore(ref.section);
      }
    }

    this.selectedReference = ref;
  }

  handleUnfocusReference() {
    if (this.selectedReference) {
      const ref = this.selectedReference;
      ref.focus = false;
      this.selectedReference = ref;
    }
  }

  // blur したら出典の強調表示を解除する
  _handleLeaveReferenceDelayedTimer: number | null = null;
  handleLeaveReferenceDelayed(delay = 1000) {
    if (this._handleLeaveReferenceDelayedTimer !== null) {
      clearTimeout(this._handleLeaveReferenceDelayedTimer);
    }

    this._handleLeaveReferenceDelayedTimer = window.setTimeout(() => {
      this.selectedReference = null;
    }, delay);
  }

  @ProvideReactive('postEvaluation')
  postEvaluation() {
    if (!this.evaluation || !this.currentSessionID) {
      return;
    }

    this.$repositories.labs
      .postLabsWandhEvaluation({ session: this.currentSessionID, ...this.evaluation })
      .then(() => this.$toast.success('評価を記録しました'))
      .catch(() =>
        this.$toast.error(
          '評価の記録に失敗しました。恐れ入りますがしばらく時間を置いてから再度同じ操作をお試しください',
        ),
      );
  }

  async updateQuota() {
    const res = await this.$repositories.labs.getLabsWandhQuota();
    this.$store.commit('updateLabsWandhQuota', res);
  }

  // 本当は handleHoveredReference と共に watson にまとめたい
  watsonTab = 'book';
  watsonTabs = ['book', 'case', 'guideline', 'law'];
  changeWatsonTab(newTab: string) {
    this.watsonTab = newTab;
  }

  // タブ切り替え時にSetTimeoutの処理が間引かれHolmesの回答が止まってしまうように見えるのを防ぐ
  handleVisibilityChange() {
    if (document.hidden) {
      // 画面非表示時の経過時間を記録
      this.lastHiddenTime = Date.now();
    } else {
      // 画面表示時の経過時間から本来の経過時間を計算
      const now = Date.now();
      const hiddenDuration = this.lastHiddenTime === 0 ? 0 : now - this.lastHiddenTime;

      // インターバルを超えている場合はそれだけ進める
      // stateはbufferingの可能性もあるがインターバルの長い方で決め打ち
      const stepInterval = this.answerInterval['holmes-thinking'];
      const stepsToSkip = Math.floor(hiddenDuration / stepInterval);

      // answerTimerCountを一気に進める
      this.answerTimerCount += stepsToSkip;
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: padding 0.25s ease-in-out;

  gap: 2em;

  @media screen and (min-width: 960px) {
    // 十分横幅が広いときは2カラムでフォームを下固定
    overflow-y: hidden;
    flex-direction: column-reverse;
    height: calc(100vh - 48px); // TODO: app-barは高さ可変かもしれない

    padding-left: 1em;
    padding-right: 1em;
  }

  > .loading {
    margin: auto;
  }
}

.result-column {
  position: relative; // referenceのoffsetParentになるために必要
  display: flex;
  flex-direction: column;

  gap: 1em;

  .to-fade {
    @media screen and (min-width: 960px) {
      // 十分横幅が広いときは2カラムでフォームを下固定
      // 入れ替わるときにカクっとならないように最初から高さをゼロにしておく
      margin: 0;
      margin-bottom: -1em;
      height: 0;
    }
  }

  @media screen and (min-width: 960px) {
    & + & {
      padding-left: calc(12px + 1em);
    }
  }
}

label {
  margin-left: 0.5em;

  input[type='radio'] {
    margin-right: 0.125em;
  }

  &:has(input[disabled]) {
    color: #888;
  }
}

@media screen and (min-width: 960px) {
  // 十分横幅が広いときは2カラムでそれぞれの中で独立にスクロールさせる
  .result-wrapper {
    overflow: hidden;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.fab {
  // 画面幅が狭いときはウィンドウ右下に固定
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;

  @media screen and (min-width: 960px) {
    // 画面幅が広い時はこのコンポーネントの右下に固定
    position: absolute;
  }
}
</style>
